@import url("https://fonts.googleapis.com/css?family=Alef:400,700&subset=latin,hebrew");

@font-face {
  font-family: 'Shuneet';
  font-weight: 400;
  font-style: normal;
  src: url('https://s3-eu-west-1.amazonaws.com/fonts-project/fonts/Shuneet/400.woff') format('woff');
}

@font-face {
  font-family: 'Shuneet';
  font-weight: 500;
  font-style: normal;
  src: url('https://s3-eu-west-1.amazonaws.com/fonts-project/fonts/Shuneet/500.woff') format('woff');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.rtl {
  direction: rtl;
}
.focus\:ring-cyan-500:focus {
  --tw-ring-opacity: 0 !important;
}